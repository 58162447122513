*, :after, :before {
  box-sizing: border-box;
}

:root {
  font-optical-sizing: auto;
  --bg: #fff1e5;
  --fg: #262a33;
  --accent: #0f5499;
  --accent-b: #9b1441;
  --accent-c: #0a5e66;
  font: 400 10px / 2 Petrona, serif;
}

html {
  border-top: .4rem solid var(--accent);
}

@media not (prefers-reduced-motion) {
  html {
    scroll-behavior: smooth;
  }
}

html, body {
  background: var(--bg);
  color: var(--fg);
  font-size: 2rem;
}

body {
  max-width: 80ch;
  margin: auto;
  padding-inline: 2rem;
}

[id="root"] {
  box-sizing: inherit;
  grid-template: "picker picker"
                 "download download"
                 "picks picks"
                 "backtotop backtotop";
  gap: 3rem 0;
  padding-block-start: 3rem;
  display: grid;
}

@media (width >= 820px) {
  [id="root"] {
    grid-template: "picker download"
                   "picks picks"
                   "backtotop backtotop";
  }
}

a {
  color: var(--accent);
  text-decoration-skip-ink: all;

  &:hover {
    color: var(--accent-b);
  }
}

h1 {
  margin-block-end: 0;
  font-family: Mulish;
  font-size: 4rem;
  line-height: 1;
}

@media screen and (width <= 600px) {
  h1 {
    line-height: 1.25;
  }
}

h2 {
  font-size: 2.6rem;
}

@media screen and (width <= 600px) {
  h2 {
    font-size: 2.2rem;
    line-height: 1.5;
  }
}

h2:first-of-type {
  margin-block-end: 4rem;
  font-style: italic;
  font-weight: 400;
}

h2, ol {
  margin-block: 0;
}

form {
  grid-area: picker;
  font-family: Mulish, sans-serif;
  font-weight: 500;

  & p {
    grid-template-columns: 1fr min-content;
    grid-template-areas: "label label"
                         "input button";
    gap: 1rem 1.6rem;
    margin-block: 0;
    display: grid;
  }

  & label {
    grid-area: label;
    width: max-content;
    line-height: 1;
  }

  & input {
    grid-area: input;
    width: 100%;
  }

  & button {
    white-space: nowrap;
    grid-area: button;

    &:hover, &:focus {
      background: var(--accent-c);
    }
  }
}

[download][hidden] {
  visibility: hidden;
  display: block;
}

[download]:not([hidden]) {
  letter-spacing: .1rem;
  text-align: center;
  white-space: nowrap;
  width: 100$ ;
  grid-area: download;
  padding: 1.1rem 2rem;
  font-family: Mulish, sans-serif;
  text-decoration: none;
  display: inline-block;

  &:hover, &:focus {
    background: var(--accent-c);
    color: #fff;
  }
}

@media (width >= 820px) {
  [download]:not([hidden]) {
    place-self: end;
    width: min-content;
  }
}

@media (width >= 600px) {
  form {
    grid-area: picker;
  }
}

@media screen and (width <= 92.6rem) {
  form {
    & label {
      font-size: 1.6rem;
      line-height: 1.25;
    }
  }
}

input, button {
  font: inherit;
  border: 1px solid #666;
  border-radius: 0;
  padding: 1rem;
}

label {
  font: inherit;
  font-size: 2.2rem;
  font-weight: bold;
}

button, [download] {
  background: var(--accent);
  color: #fff;
  cursor: pointer;
  letter-spacing: .1rem;
  font-variant: small-caps;
  border: 0;
  padding-inline: 2rem;
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border: 1px solid #666;
  border-inline-width: 0;
  width: 100%;
  font-family: Mulish, sans-serif;
  font-size: 1.8rem;
}

@media (width >= 600px) {
  table {
    grid-area: picks;
  }
}

th {
  text-align: start;
  font-variant: small-caps;
  vertical-align: bottom;
  border-block-end: 4px double #666;
  font-weight: 700;
  line-height: 1.5;
}

th, td {
  padding-block: 1rem;
}

:is(th, td):first-child {
  padding-inline: 1rem 0;
}

tr:nth-child(2n) {
  background: #0000000d;
}

ol {
  & ::marker {
    color: var(--accent-c);
    padding-inline-end: 3rem;
    font-family: Mulish, sans-serif;
    font-size: 2rem;
    display: inline-block;
  }
}

em {
  font-style: italic;
  text-decoration: underline 1px;
}

[id="attribution"] {
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-block: 3rem;
  font-family: Mulish, sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  display: flex;

  & a {
    flex: 0 0 10rem;
  }

  & svg {
    pointer-events: none;
    width: 100%;
    margin-bottom: -.25rem;
    display: inline-block;
  }

  & span {
    display: inline-block;
  }
}

@media screen and (width <= 92.6rem) {
  [id="attribution"] {
    flex-direction: column;
    align-items: end;
    line-height: 1.5;

    & a {
      flex: 0 0 min-content;
    }

    & svg {
      width: 12rem;
      height: auto;
    }

    & span {
      text-align: right;
    }
  }
}

[id="toplink"] {
  text-align: end;
  text-transform: uppercase;
  grid-area: backtotop;
  margin-block: 0;
  font-family: Mulish, sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
}

[id="disclaimer"] {
  margin-block: 3rem;
  font-size: 1.8rem;
  line-height: 1.5;
}
/*# sourceMappingURL=index.b364e26c.css.map */
